// api.js
import {useContext} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EventEmitter from 'events';

const eventEmitter = new EventEmitter();

// Axios 인스턴스를 생성
const api = axios.create({
  baseURL: 'https://service.partnersai.kr', // API의 기본 URL
  //baseURL: 'http://localhost', // for development
//   headers: {
//     'Content-Type': 'application/json',
//   },
});

// 요청 인터셉터를 통해 매번 Authorization 헤더를 동적으로 추가
api.interceptors.request.use(
    (config) => {
    //   const token = localStorage.getItem('jwtToken');
    //   if (token) {
    //     config.headers.Authorization = `Bearer ${token}`;
    //   }

    //   if (config.headers["Content-Type"] == null ) {
    //     config.headers["Content-Type"] = 'application/json';
    //   }
        if( config.url != '/api/login' ) {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
         
        if (config.headers["Content-Type"] == null ) {
            // FormData 객체인지 확인하여 파일 전송 여부 판단
            if (!(config.data instanceof File) && !(config.data instanceof FormData)) {
            // 파일/폼데이터  아닌 경우 'application/json'으로 설정
                config.headers['Content-Type'] = 'application/json';
            }
            // 파일 전송인 경우는 'Content-Type'을 지정하지 않음 (Axios가 자동 처리)
        }
        return config;
    },
    (error) => {
      return Promise.reject(error);
    }
);

// 응답 인터셉터 설정
api.interceptors.response.use(
  (response) => {
    // 응답이 성공적이면 그대로 리턴
    return response;
  },
  (error) => {
    // 401 오류가 발생할 경우 처리
    if (error.response && error.response.status === 401) {
    // 사용자 알림 (React UI 라이브러리 사용 권장)
    // alert 대신 React 컴포넌트를 사용한 알림으로 변경할 것
      alert('로그인 유효시간이 만료되어 로그인 페이지로 이동합니다');
      
      eventEmitter.emit('logout');
      // 토큰 제거
//      localStorage.removeItem('jwtToken');

      // 로그인 페이지로 리다이렉션 (React Router navigate 권장, 또는 window.location.href)
//      window.location.href = '/';
    }
    else {
        alert(error.message);
        //alert(error.response.message);
    }
    
    // 오류를 그대로 throw하여 각 API 요청에서 처리할 수 있게 함
    return Promise.reject(error);
    

  }
);

export { eventEmitter, api };