import React, { useCallback, useState } from 'react';
import { api } from './Api'

// 트리 노드를 정의하는 컴포넌트
const TreeNode = ({ node, updateQueryHistory } ) => {
  // 노드의 펼침 상태를 관리하는 상태 변수
  const [isExpanded, setIsExpanded] = useState(false);
  // 노드를 클릭하여 펼치거나 접는 함수
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleUpdateQueryHistory = useCallback((qh) => { 
    updateQueryHistory(qh); 
  }, []);

  // 리프 노드 클릭 시 호출되는 함수
  const handleClickLeaf = async () => {
    //alert(`Leaf node clicked: ${node.label}`);
    const request_body = {
            year: node.year,
            month: node.month,
            day : node.day
          }
    try {
      // const response = await fetch(
      //   '/api/history',
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${localStorage.getItem('jwtToken')}`
      //     },
      //     body: JSON.stringify(request_body)
      //   }
      // );

      // if(!response.ok) {
      //   throw Error('');
      // }

      // const result = await response.json();
      const response = await api.post('/api/history', request_body);
      const result = response.data;
      const qa_list = {};
      
      for(let i=0; i < result.query_log_list.length; i++){
        const query_log = result.query_log_list[i];
        const date = query_log.timestamp.split("T")[0];
        if( !(date in qa_list) ) {
          qa_list[date] = [];
        }

        const entry = {
          question: query_log["query"],
          answer: "",
          documents: []
        };
        if (query_log["response_body"]) {
          // const entry = {
          //   question: query_log["query"],
          //   answer: query_log["response_body"]["response"],
          //   documents: query_log["response_body"]["relavant_docs"]
          // };
          entry["answer"] = query_log["response_body"]["response"];
          entry["documents"] = query_log["response_body"]["relavant_docs"];
        } 
        //qa_list[date].push(entry)
        // push entry into front of list
        qa_list[date] = [entry, ...qa_list[date]];
        
      }
      console.log(qa_list);
      handleUpdateQueryHistory(qa_list);

    } catch(error) {
      console.log('history_years fail', error);
    }

  };

  // 리프 노드를 클릭했을 때의 처리
  const handleClick = (event) => {
    event.stopPropagation(); // 클릭 이벤트가 부모로 전파되는 것을 방지
    if (!node.children) {
      handleClickLeaf(node);
    } else {
      handleToggle();
    }
  };

  return (
    <div style={{ marginLeft: '20px' }}>
      {/* 노드 제목 */}
      <div onClick={handleClick} style={{ cursor: 'pointer' }} data-year={node.year} data-month={node.month} data-day={node.day}>
        {node.children && ( // 자식 노드가 있을 때만 펼침/접힘 아이콘 표시
          <span style={{ marginRight: '5px' }}>
            {isExpanded ? '-' : '+'}
          </span>
        )}
        {node.label}
      </div>

      {/* 자식 노드 렌더링 */}
      {isExpanded && node.children && (
        <div>
          {node.children.map((childNode) => (
            <TreeNode key={childNode.label} node={childNode} updateQueryHistory={updateQueryHistory}/>
          ))}
        </div>
      )}
    </div>
  );
};

// 트리 컴포넌트 정의
const Tree = ({ data, updateQueryHistory } ) => {
  return (
    <div>
      {data.map((node) => (
        <TreeNode key={node.label} node={node} updateQueryHistory={updateQueryHistory} />
      ))}
    </div>
  );
};

export default Tree;