import React, { useState, useEffect, useRef } from 'react';
import { api } from './Api'

function Query({queryHistory, updateQueryHistory}) {
  const [query, setQuery] = useState('');
  const [answer, setAnswer] = useState('');
  const [documents, setDocuments] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  // const [queryHistory, setQueryHistory] = useState(
  //   JSON.parse(localStorage.getItem('queryHistory')) || {}
  // ); // State to hold query history
  const [isLoading, setIsLoading] = useState(false); // state to manage loading
  const [ selectedFiles, setSelectedFiles ] = useState([]); // state to manage selected files
  const [ uploadFileIDs, setUploadFileIDs ] = useState([]);
  const displayLimit = 10; // Adjust based on how many documents you want to show initially

  const chatEndRef = useRef(null); // Ref for the bottom of the chat
  const textareaRef = useRef(null);
  
  const handleUpdateQueryHistory = (qh) => {updateQueryHistory(qh)};

  // Function to handle query submission and fetch the response
  async function submitQuery() {
    if (!query) {
      alert('Please enter a question.');
      return;
    }

    setIsLoading(true); // Set loading to true when starting the request

    const requestBody = {
      query: query,
      fileid: uploadFileIDs, // Replace with the actual file IDs if needed
    };

    try {
      // const response = await fetch(
      //   '/api/query',
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
      //     },
      //     body: JSON.stringify(requestBody),
      //   }
      // );

      //if (response.ok) {
        // const data = await response.json();

      const response = await api.post('/api/query', requestBody);
      if (response.status == 200) {
        const data = response.data;
        console.log('Full response data:', data); // Log the full response

        // Validate and log relevant_docs
        const relatedDocs = data.relavant_docs || [];
        console.log('Relevant documents:', relatedDocs);

        // Save the new query, answer, and related documents with today's date
        const today = new Date().toISOString().split('T')[0]; // Get YYYY-MM-DD
        const updatedHistory = {
          ...queryHistory,
          [today]: [
            {
              question: query,
              answer: data.response,
              documents: relatedDocs, // Store related documents for this query
            },
            ...(queryHistory[today] || []),
          ],
        };

        // Log the updated history and save to local storage
        console.log('Updated query history:', updatedHistory);
        //setQueryHistory(updatedHistory);
        handleUpdateQueryHistory(updatedHistory);
        localStorage.setItem('queryHistory', JSON.stringify(updatedHistory));
        setAnswer(data.response || 'No response received');
        setDocuments(relatedDocs); // Store documents in state

        // Clear input field
        setQuery('');
        
        setSelectedFiles([])
        setUploadFileIDs([])

      } else {
        const errorText = await response.text();
        console.error('Failed to fetch query result:', errorText);
        alert('Failed to fetch query result. Please try again.');
      }
    } catch (error) {
      // error 는 항상 전달되므로 401 아닐 때만 처리
      // if (error.status != 401) {
      //   alert(error.message);
      // }
      //alert('An error occurred. Please try again.');

    } finally {
      setIsLoading(false); // Set loading to false when the request is complete
    }
    
  }

  // Function to handle document download
  const handleDocDownload = (docid, doctitle) => {
    // const jwtToken = localStorage.getItem('jwtToken');
    // if (!jwtToken) {
    //   alert('You need to be logged in to download documents.');
    //   return;
    // }

    console.log('Starting document download with docid:', docid);
    //console.log('Token: ', jwtToken);

    // fetch(`/api/download`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${jwtToken}`,
    //   },
    //   body: JSON.stringify({ docid }),
    // })
    //   .then((response) => {
    //     if (!response.ok) {
    //       return response.text().then((text) => {
    //         // Log the response text to get more information
    //         console.error('Response error:', text);
    //         throw new Error('Failed to download document');
    //       });
    //     }
    //     return response.blob();
    //   })
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = doctitle; // Customize the file name if needed
    //     document.body.appendChild(a);
    //     a.click();
    //     a.remove();
    //   })
    //   .catch((error) => {
    //     console.error('Error downloading document:', error);
    //     console.log('Token: ', jwtToken);

    //     //alert('Failed to download document. Please try again.');
    //   });

    api.post('/api/download', JSON.stringify({docid}),
      { 
        responseType: 'blob' // 서버 응답을 blob으로 설정
      }
    )
    .then((response) => {
        return response.data
    })
    .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = doctitle; // Customize the file name if needed
        document.body.appendChild(a);
        a.click();
        a.remove();      
    })
    .catch((error) => {
      console.error('Error downloading document:', error);
    })
  };

  // Function to handle file selection to upload
  const handleFileChange =  async (event) => {
    const targetFiles = event.target.files;
    //const newFileNames = Array.from(targetFiles);
    let newFileNames = [];
    let newFileIDs = [];

    //setSelectedFiles(selectedFiles.concat(newFileNames));
    
    for (let i = 0; i < targetFiles.length; i++) {
      const fileid = await handleUpload(targetFiles[i]); // 파일별로 업로드 처리
      if (fileid != null) {
        newFileNames = [...newFileNames, targetFiles[i]];
        newFileIDs = [...newFileIDs, fileid];
      }
      // else {
      //   console.log('handleUpload error', error);
      // }
    };
    setSelectedFiles(selectedFiles.concat(newFileNames))
    setUploadFileIDs(uploadFileIDs.concat(newFileIDs))
  };

  const handleUpload = async (file) => {
    console.log(file.name)
    const formData = new FormData();
    formData.append('file', file);

    var fileid = null;

    try {
      // const response = await fetch('/api/upload', {
      //   method: 'POST',
      //   body: formData,
      //   headers: {
      //     /* 'Content-Type': 'application/json', */
      //     Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
      //   },
      //   //body: JSON.stringify(requestBody),
      // });

      
      // if (response.ok) {
      //   const result = await response.json();

      //   if (result.success == true) {
      //     console.log(`Upload successful: ${result.description}`);
      //     fileid = result.description
      //   }

      // } else {
      //   console.log(`Upload failed`);
      // }

      const response = await api.post('/api/upload', formData);
      const result = response.data;

      if (result.success) {
        console.log(`Upload successful: ${result.description}`);
        fileid = result.description
      }

    } catch (error) {
      console.error('Error uploading file:', error);
    }

    return fileid;
  };




  const handleRemoveFile = async (index) => {
    // call API to cancel upload
    try {
      const requestBody = {
        fileid: uploadFileIDs[index]
      };
      // const response = await fetch(
      //   '/api/cancel_upload',
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
      //     },
      //     body: JSON.stringify(requestBody),
      //   }
      // );

      // if (response.ok) {    
      // }

      const response = await api.post('/api/cancel_upload', requestBody);
  
    }
    catch(error){
      console.log('handleRemoveFile error', error)
    }
    finally {

    }

    console.log( `removing ${selectedFiles[index].name} ${uploadFileIDs[index]}`)
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index));
    setUploadFileIDs(uploadFileIDs.filter((_, i) => i !== index));
  };
  // Function to scroll to the bottom of the chat
  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Scroll to bottom whenever query history is updated
  useEffect(() => {
    scrollToBottom();
  }, [queryHistory]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      //textarea.style.height = 'auto'; // 높이 초기화
      //textarea.style.height = `${textarea.scrollHeight}px`; // 내용에 맞춰 자동 조정

      // 스크롤바 제어: 다섯 줄 이상일 때만 스크롤바가 보이도록 설정
      //if (textarea.scrollHeight > parseInt(getComputedStyle(textarea).lineHeight) * 4) {
      if (textarea.scrollHeight > parseFloat(getComputedStyle(textarea).maxHeight )) {
        textarea.style.overflowY = 'auto'; // 스크롤바 활성화
      } else {
        textarea.style.overflowY = 'hidden'; // 스크롤바 숨김
      }
    }
  }, [query]);

  const handleShowMore = () => setIsExpanded(true);
  const handleShowLess = () => setIsExpanded(false);

  return (
    <div className="row justify-content-center">
      <div className="col-md-9 col-lg-9 col-xl-8">
        {/* Render loading indicator */}
        {isLoading && (
          <div className="loading-overlay">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}

        {/* Render the query history */}
        {Object.keys(queryHistory)
          .sort((a, b) => new Date(a) - new Date(b)) // 날짜를 오름차순으로 정렬
          .map((date, index) => (
            <div key={index}>
              <p>{date}</p>
              {queryHistory[date]
                .slice() // Create a copy of the array
                .reverse() // Reverse the array for the most recent queries at the bottom
                .map((entry, entryIndex) => (
                  <div key={entryIndex}>
                    <div className="card w-75 chat-rounded mb-5 mt-5 px-3 ms-auto">
                      <div className="card-body">
                        <p className="card-text">{entry.question}</p>
                      </div>
                    </div>

                    <p
                      className="card main-answer box mb-3"
                      style={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {entry.answer}
                    </p>

                    {/* Render related documents for each query */}
                    {entry.documents && entry.documents.length > 0 && (
                      <>
                        <p className="documents">
                          <strong>
                            관련성 높은 문서 ({entry.documents.length}개):
                          </strong>
                        </p>
                        <div className="card card-answer mb-1 md-4">
                          <div className="card-body">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">No.</th>
                                  <th scope="col">문서 제목</th>
                                  <th scope="col">관련도</th>
                                </tr>
                              </thead>
                              <tbody>
                                {entry.documents
                                  .slice(
                                    0,
                                    isExpanded
                                      ? entry.documents.length
                                      : displayLimit
                                  )
                                  .map((doc, index) => (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() => 
                                            handleDocDownload(doc.docid, doc.title)
                                          }
                                        >
                                          {doc.title}
                                        </a>
                                      </td>
                                      <td>{doc.score.toFixed(5)}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {entry.documents.length > displayLimit && (
                              <div>
                                {isExpanded ? (
                                  <button
                                    className="btn btn-link documentHandler"
                                    onClick={handleShowLess}
                                  >
                                    접기
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-link documentHandler"
                                    onClick={handleShowMore}
                                  >
                                    더 보기
                                  </button>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          ))}

        {/* Input for new query */}
        <div className="input-container">
          {/* Display selected files as thumbnails */}
          <div className="file-thumbnails">
            {selectedFiles.map((file, index) => (
              <div key={index} className="file-thumbnail">
                <i className="bi bi-file-earmark"></i> {file.name}
                <button className="file-remove-button"
                    onClick={() => handleRemoveFile(index)}
                    aria-label="Remove file"
                >
                  ×
                </button>
              </div>
            ))}
          </div>
          
          <input
            type="file"
            multiple
            accept=".hwp, .txt, .pdf, text/plain, application/pdf"
            onChange={handleFileChange}
            className="btn btn-outline-secondary btn-file-upload" // New button class
            style={{ display: 'none' }} // Hide the default input appearance
            id="file-upload"
          />
          <label htmlFor="file-upload" className="btn btn-outline-secondary btn-file-upload">
            <i className="bi bi-paperclip"></i>
          </label>

          <button
            className="btn btn-outline-secondary btn-inside-textarea"
            type="button"
            onClick={submitQuery} // Handle query submission
          >
            <i className="bi bi-arrow-up"></i>
          </button>
          <textarea
            className="form-control custom-input-bg"
            placeholder="질문을 입력하세요"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            rows="1"
            ref={textareaRef}
            value={query}
            onChange={(e) => {
              e.target.style.height = 'auto';
              e.target.style.height = e.target.scrollHeight + 'px';
              setQuery(e.target.value); // Capture input
            }}
            style={{
              resize: 'none',
              overflow: 'auto',
            }}
          />
        </div>

        {/* Ref to scroll to the bottom */}
        <div ref={chatEndRef}></div>
      </div>
    </div>
  );
}

export default Query;

